<template>
  <div class="aj-search-page">
    <HeaderAnnualMeeting />
    <div style="height: 400px;">
      <HeaderAJ />
    </div>
    <div class="cont ">
      <div class="wid1200">
        <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
      </div>
      <el-row style="margin-top: 20px;">
        <el-col :span="7" style="margin-top: 20px;">
          <div class="tree_box" :class="{'on':showSort1}">
            <h5 class="tree_sort" @click="changeSort(1)"><i :class="{'on':showSort1}"></i>学科导航</h5>
            <!-- 集刊分类导航 -->
            <div class="sort_box" v-show="showSort1">
              <p :class="{all:classifyId==''}" @click="changeClassify()">
                <!-- <i><</i> -->全部
              </p>
              <p :class="{all:classifyId==item.value}" v-for="(item, index) in typeList" :key="index"
                 @click="changeClassify(item.value)">
                <!-- <i><</i> -->{{item.name}}
              </p>
            </div>
          </div>
          <div class="tree_box" :class="{'on':showSort2}">
            <h5 class="tree_sort" @click="changeSort(2)"><i :class="{'on':showSort2}"></i>主办单位导航</h5>
            <div class="sort_box" v-show="showSort2">
              <p :class="{all:subjectId==''}" @click="changeSubject()">
                <!-- <i><</i> -->全部
              </p>
              <p :class="{all:subjectId==item.value}" v-for="(item, ind) in subjectList" :key="ind"
                 @click="changeSubject(item.value)">{{item.name}}
              </p>
            </div>
          </div>

          <div class="tree_box" :class="{'on':showSort3}">
            <h5 class="tree_sort" @click="changeSort(3)">
              <i :class="{'on':showSort3}"></i>出版单位导航
            </h5>
            <!-- 刊期导航 -->
            <div class="sort_box" v-show="showSort3">
              <p :class="{all:publisherType==''}" @click="changePublisher()">
                <!-- <i><</i> -->全部
              </p>
              <p :class="{all:publisherType==item.value}" v-for="(item, ind) in qikanList" :key="ind"
                 @click="changePublisher(item.value)">{{item.name}}</p>
            </div>
          </div>
          <div class="tree_box" :class="{'on':showSort4}">
            <h5 class="tree_sort" @click="changeSort(4)"><i :class="{'on':showSort4}"></i>创刊年代导航</h5>
            <!-- 优秀集刊导航 -->
            <div class="sort_box" v-show="showSort4">

              <p :class="{all:yearChoose==''}" @click="changeYear()">
                <!-- <i><</i> -->全部
              </p>
              <p :class="{all:yearChoose==item.name}" v-for="(item, ind) in excllentList" :key="ind"
                 @click="changeYear(item.name)">{{item.name}}</p>
            </div>
          </div>
          <div class="tree_box" :class="{'on':showSort5}">
            <h5 class="tree_sort" @click="changeSort(5)"><i :class="{'on':showSort5}"></i>出版周期</h5>
            <!-- 名录集刊导航 -->
            <div class="sort_box" v-show="showSort5">
              <p :class="{all:cycleVal==''}" @click="changeCycle()">
                <!-- <i><</i> -->全部
              </p>
              <p :class="{all:cycleVal==item.value}" v-for="(item, ind) in cycleList" :key="ind"
                 @click="changeCycle(item.value)">{{item.name}}</p>

              <!-- <p :class="{all:directoryCollect===''}" @click="changeType(3, '')"><i><</i>全部</p>
              <p :class="{all:directoryCollect==item}" v-for="(item, ind) in directoryCollectList" :key="ind" @click="changeType(3,item)"><i><</i>{{item}}</p> -->

              <!-- <p :class="{all:directoryCollect==item}" v-for="(item, ind) in cycleList" :key="ind" @click="changeType(3,item)"><i><</i>{{item}}</p> -->

              <!-- <p :class="{all:directoryCollect===1}"  @click="changeType(3, 1)"><i><</i>名录集刊</p> -->
            </div>
          </div>
        </el-col>
        <el-col :span="17">
          <!--<div class="search_header">-->
          <!--<el-input type="text" placeholder="请输入关键词"></el-input><el-button>立即检索</el-button>-->
          <!--</div>-->
          <div class="tab_box">
            <span>检索：{{searchKey}} 共<i style="font-style: normal;"> {{totalNum}} </i>种集刊</span>
            <el-input class="fr" type="text" v-model="secondKey" placeholder="请输入集刊名称" suffix size="mini"
                      @keyup.enter.native="toSearch"><i class="el-icon-search el-input__icon" slot="suffix"
                                                        style="color: #bd1a2d" @click="toSearch"></i></el-input>
          </div>
          <div class="book-list">
            <ul>
              <li v-for="(item, index) in bookList" :key="index" @click="goBook(item)">
                <!-- <img src="../assets/img/book.png" alt="" /> -->
                <img :src="item.imgPath" alt="" />
                <p v-html="item.name"></p>
              </li>
            </ul>
          </div>
          <div class="pagination-wrap">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="curpage" :page-sizes="[100, 200, 300, 400]" :page-size="20"
                           layout="total, prev, pager, next, jumper" :total="totalNum">
            </el-pagination>
          </div>
        </el-col>

      </el-row>
    </div>
  </div>
</template>

<script>
import HeaderAnnualMeeting from '../components/HeaderAnnualMeeting'
import HeaderAJ from '../components/HeaderAJ'
import BreadCrumb from '../components/BreadCrumb'
var curDate = new Date()

export default {
  name: 'collectOverview',
  data() {
    return {
      pageName: '集刊导览',
      pN: '',
      bookList: [],
      typeList: [],
      subjectList: [],
      qikanList: [],
      excllentList: [{
        id: 1,
        startYear: '2020',
        endYear: curDate.getFullYear(),
        name: "2020-2022"
      },
        {
          id: 2,
          startYear: '2010',
          endYear: '2019',
          name: "2010-2019"
        },
        {
          id: 3,
          startYear: '2000',
          endYear: '2009',
          name: "2000-2009"
        },
        {
          id: 4,
          startYear: '1990',
          endYear: '1999',
          name: "1990-1999"
        },
        {
          id: 5,
          startYear: '1900',
          endYear: '1989',
          name: "1990年以前"
        }],
      cycleList: [],
      classifyId: '',
      subjectId: '',
      publisherType: '',
      yearChoose: '',
      cycleVal: '',
      showSort1: false,
      showSort4: false,
      showSort3: false,
      showSort2: false,
      showSort5: false,
      curpage: 1,
      totalNum: 0,

      searchType: 1,
      searchKey: '',
      secondKey: '',

      // typeList
      // subjectList
      // qikanList
      // excllentList
      // cycleList
    }
  },
  components: { HeaderAnnualMeeting, HeaderAJ, BreadCrumb },
  watch: {
    $route: 'init',
  },
  created() {
    this.init()
  },
  methods: {
    init(val) {
      let _this = this
      this.secondKey = ''
      this.bookList = []
      this.typeList = []
      this.subjectList = []
      this.qikanList = []
      // this.excllentList = []
      this.cycleList = []
      this.curpage = 1
      this.totalNum = 0

      this.classifyId = ''
      this.subjectId = ''
      this.publisherType = ''
      this.yearChoose = ''
      this.cycleVal = ''
      this.showSort1 = false
      this.showSort2 = false
      this.showSort3 = false
      this.showSort4 = false
      this.showSort5 = false
      if (_this.$route.query.filterNum) {
        switch (parseInt(_this.$route.query.filterNum)) {
          case 1:
            _this.showSort1 = true
            // _this.classifyId = _this.$route.query.filterVal
            break
          case 2:
            _this.showSort2 = true
            // _this.subjectId = _this.$route.query.filterVal
            break
          case 3:
            _this.showSort3 = true
            // _this.publisherType = _this.$route.query.filterVal
            break
          case 4:
            _this.showSort4 = true
            // _this.excllentList.forEach(e => {
            //     if (e.startYear == parseInt(_this.$route.query.filterVal)) {
            //         _this.yearChoose = e.name
            //     }
            // })
            break
          case 5:
            _this.showSort5 = true
            // _this.cycleVal = _this.$route.query.filterVal
            break
        }
      } else {
        _this.showSort1 = true
      }
      if (this.$route.query.pN) {
        this.pN = decodeURI(this.$route.query.pN)
      } else {
        this.pN = ''
      }
      if (this.$route.query.ajType) {
        this.searchType = parseInt(this.$route.query.ajType)
      }
      if (this.$route.query.searchKey) {
        this.searchKey = this.$route.query.searchKey
      }

      // this.getbookList()
      this.getFilterData()
      this.getData()
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.curpage = val
      this.getData()
    },
    goBook(item) {
      if (item.collectedPapersType == 1) {
        //社外
        this.$router.push({ path: '/ajC/a', query: { id: item.id, pN: this.pageName } })
      } else {
        //社内
        this.$router.push({ path: '/c/c', query: { id: item.id, pN: this.pageName } })
      }
    },
    changeSort(num) {
      // this.curpage = 1
      if (num == 1) {
        this.showSort1 = !this.showSort1
      } else if (num == 2) {
        this.showSort2 = !this.showSort2
      } else if (num == 3) {
        this.showSort3 = !this.showSort3
      } else if (num == 4) {
        this.showSort4 = !this.showSort4
      } else if (num == 5) {
        this.showSort5 = !this.showSort5
      }
    },
    changeClassify(val) {
      this.curpage = 1
      if (val) {
        if (this.classifyId == val) {
          this.classifyId = ''
        } else {
          this.classifyId = val
        }
      } else {
        this.classifyId = ''
      }
      this.getData()
    },
    changeSubject(val) {
      this.curpage = 1
      if (val) {
        if (this.subjectId == val) {
          this.subjectId = ''
        } else {
          this.subjectId = val
        }
      } else {
        this.subjectId = ''
      }
      this.getData()
    },
    changePublisher(val) {
      this.curpage = 1
      if (val) {
        if (this.publisherType == val) {
          this.publisherType = ''
        } else {
          this.publisherType = val
        }
      } else {
        this.publisherType = ''
      }
      this.getData()
    },
    changeYear(val) {
      this.curpage = 1
      if (val) {
        if (this.yearChoose == val) {
          this.yearChoose = ''
        } else {
          this.yearChoose = val
        }
      } else {
        this.yearChoose = ''
      }
      this.getData()
    },
    changeCycle(val) {
      this.curpage = 1
      if (val) {
        if (this.cycleVal == val) {
          this.cycleVal = ''
        } else {
          this.cycleVal = val
        }
      } else {
        this.cycleVal = ''
      }
      this.getData()
    },
    getData() {
      let _this = this
      var x = ''
      var y = ''
      if (this.yearChoose) {
        this.excllentList.forEach((e) => {
          if (this.yearChoose == e.name) {
            x = e.startYear + '-01-01'
            y = e.endYear + '-12-31'
          }
        })
      }
      this.$http({
        method: 'post',
        url: '/admin/api/search/find',
        data: {
          type: this.searchType, // 检索类型：1、全文 2、集刊名称  5、主办单位 6、出版单位  默认全文
          searchKeyword: this.searchKey, //检索词
          titleKeyword: this.secondKey,
          classifyId: this.classifyId, // 学科id
          cycle: this.cycleVal, // 出版周期
          insNameList: this.subjectId, // 主办单位
          publisher: this.publisherType, //出版单位
          collectedPapersType: 'ALL', // 集刊类型 ALL:全部 0:社内(默认) 1:社外
          resourceType: 'COLLECTED_PAPERS', //  // 固定字段， 集刊和 外部集刊
          onlineTimeStartDate: x, // 创刊时间开始时间
          onlineTimeEndDate: y, // 创刊时间结束时间
          pageNo: this.curpage, //页码
          pageSize: 20, // 每页条数
        },
      }).then((res) => {
        if (res.data.code == 0) {
          let data = res.data.data
          _this.bookList = data.rows
          _this.totalNum = data.total
        }
      })
    },
    getFilterData(val) {
      let _this = this

      // yearChoose==item.value}"  v-for="(item, ind) in excllentList
      var x = ''
      var y = ''
      if (this.yearChoose) {
        this.excllentList.forEach((e) => {
          if (this.yearChoose == e.name) {
            x = e.startYear + '-01-01'
            y = e.endYear + '-12-31'
          }
        })
      }
      this.$http({
        method: 'post',
        url: '/admin/api/search/filterCondition',
        data: {
          type: this.searchType, // 检索类型：1、全文 2、集刊名称  5、主办单位 6、出版单位  默认全文
          searchKeyword: this.searchKey, //检索词
          titleKeyword: this.secondKey,
          classifyId: this.classifyId, // 学科id
          cycle: this.cycleVal, // 出版周期
          insNameList: this.subjectId, // 主办单位
          publisher: this.publisherType, //出版单位
          collectedPapersType: 'ALL', // 集刊类型 ALL:全部 0:社内(默认) 1:社外
          resourceType: 'COLLECTED_PAPERS', //  // 固定字段， 集刊和 外部集刊
          onlineTimeStartDate: x, // 创刊时间开始时间
          onlineTimeEndDate: y, // 创刊时间结束时间
          pageNo: this.curpage, //页码
          pageSize: 2000, // 每页条数
        },
      }).then((res) => {
        if (res.data.code == 0) {
          let data = res.data.data
          data.forEach((e) => {
            if (e.typeName == '学科分类') {
              _this.typeList = e.conditions
            } else if (e.typeName == '主办单位') {
              _this.subjectList = e.conditions
            } else if (e.typeName == '出版单位') {
              _this.qikanList = e.conditions
              // console.log('111111', _this.qikanList)
            } else if (e.typeName == '创刊年代') {
              _this.excllentList = e.conditions
            } else if (e.typeName == '出版周期') {
              _this.cycleList = e.conditions
            }
          })

        }
      })
    },
    toSearch() {
      // this.bookList = []
      // this.typeList = []
      // this.subjectList = []
      // this.qikanList = []
      // // this.excllentList = []
      // this.cycleList = []
      // this.classifyId = ''
      // this.subjectId = ''
      // this.publisherType = ''
      // this.yearChoose = ''
      // this.cycleVal = ''
      // this.showSort1 = false
      // this.showSort2 = false
      // this.showSort3 = false
      // this.showSort4 = false
      // this.showSort5 = false
      this.curpage = 1
      this.totalNum = 0
      this.getFilterData()
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
.back_box {
  width: 1200px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.cont {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
}
.braed {
  margin: 15px 0;
}
.search_header .el-input {
  width: 752px;
  display: inline-block;
}
.search_header .el-input input {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.tab_box {
  padding: 0 20px;
  /*margin-top: 20px;*/
  margin-bottom: 0px;
  border: 2px solid #dff1f1;
  padding-right: 10px;
  height: 46px;
  line-height: 46px;
  border-radius: 4px;
  color: #808080;
}
.tab_box .el-input {
  width: 217px;
  line-height: 43px;
}
.tab_box .el-input >>> .el-icon-search {
  color: #bd1a2d;
  font-weight: 700;
  cursor: pointer;
}
/*.tab_box.tab_box_last {
    border: none;
    padding-right: 0;
    margin-bottom: 0px;
}*/
.tab_box > span > i {
  color: #bd1a2d;
}
.tab_box .fr a {
  padding: 0 10px;
}
.tab_box .fr a:hover {
  color: #e58e88;
}
.tab_box .fr a.on {
  color: #bd1a2d;
}
.tab_box .fr a:last-child {
  margin-right: 0;
}

.tree_box {
  width: 280px;
  margin-left: 20px;
  padding: 0 12px 10px;
  /*position: relative;*/
  border-top: 2px solid #bd1a2d;
  margin-bottom: 20px;
}
.tree_box.on {
  border: 1px solid #d1d1d1;
  border-top: 2px solid #bd1a2d;
  background: #fff;
  box-shadow: 8px 8px 1px rgba(0, 0, 0, 0.3);
  margin-bottom: 36px;
}
.tree_sort {
  position: relative;
  top: -10px;
  left: 15px;
  width: 200px;
  margin-top: -20px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  /*color: #bd8872;*/
  /*border-bottom: 2px solid #BD1A2D;*/
  cursor: pointer;
  color: #fff;
  background: #bd1a2d;
}
.tree_sort > i {
  float: left;
  width: 18px;
  height: 18px;
  margin-top: 14px;
  margin-left: 10px;
  margin-right: 10px;
  background: url('../assets/img/icon_torr_close.png') no-repeat;
  background-size: 100% 100%;
}
.tree_sort > i.on {
  background-image: url('../assets/img/icon_torr_down.png');
}
.sort_box {
  position: relative;
  /*width: 245px;*/
  width: 100%;
  height: 241px;
  /*325px;*/
  overflow-y: auto;
  /*border-top: 1px solid #ccc;*/
}
.sort_box > p {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  text-indent: 22px;
  border-bottom: 1px dashed #f0f0f0;
  /*background: #f5f5f5;*/
  cursor: pointer;
  box-sizing: border-box;
  padding-right: 10px;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sort_box > p:last-child {
  border: none;
}
.sort_box > p:before {
  content: '';
  float: left;
  margin-top: 17px;
  margin-left: 25px;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #b5b5b5;
  border-radius: 50%;
}
.sort_box > p.all:before {
  /*display: none;*/
  background: #bd1a2d;
}

.sort_box > p.all {
  font-size: 16px;
  /*text-indent: 0;*/
  text-indent: 22px;
  /*color: #fff;*/
  /*border-top: 1px solid #fff;*/
  color: #bd1a2d;
  /*#55b9c1;*/
  /*background: #b89781;*/
}
.sort_box > p i {
  display: none;
}
.sort_box > p.all i {
  display: block;
  float: left;
  width: 14px;
  height: 14px;
  /*margin-top: 8px;*/
  margin-left: 10px;
  /*background: url("../assets/img/icon_left.png") no-repeat;*/
  background-size: 100% 100%;
}
td a {
  color: #292929;
}
.book-list {
  margin-top: 7px;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 159px;
      margin-right: 13px;
      margin-bottom: 42px;
      position: relative;
      cursor: pointer;
      img {
        display: block;
        width: 100%;
        height: 224px;
        cursor: pointer;
      }
      p {
        width: 100%;
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        font-size: 14px;
        color: #ffffff;
        line-height: 30px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 6px;
        cursor: pointer;
      }
    }
    li:nth-child(5n + 5) {
      margin-right: 0;
    }
  }
}

.pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .total-page {
    font-size: 14px;
    color: #333 !important;
    margin-right: 4px;
  }
}
</style>


